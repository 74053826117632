<template>
  <div class="addFalsePositiveZabbixEvent">
    <LoadingPlaceholder v-show="blockButton" />
    <form v-show="!blockButton">
      <div class="form-group">
        <h4 class="m-0 mt-3">
          Zabbix SLA Event Comment
        </h4>
        <span class="m-0 mb-3" />
        <label>{{ $t("comment") }}</label>
        <textarea
          v-model="description"
          class="form-control m-input m-input--air"
          name="Comment"
          rows="5"
          placeholder="Please enter your comment"
        />
        <div class="m-0 mb-3" />
      </div>
      <button
        class="btn btn-primary float-right"
        :disabled="!description || blockButton
        "
        @click.prevent="updateZabbixEventComment()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="check"
        />{{ $t("add") }}
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
import { authenticationMixin } from "@/mixins/authenticationMixin";
import { kendoGridMixin } from "@/mixins/kendoGridMixin.js";

export default {
  name: "CommentZabbixEvent",
  mixins: [kendoGridMixin, authenticationMixin],
  props: {
    id: {
      type: Number,
      required: true,
    },
    comment: {
      type: String,
      required: false,
      default: null
    },
  },
  data() {
    return {
      loading: false,
      blockButton: false,
      description: null,
    };
  },
  created() {
    this.description = this.comment
  },
  methods: {
    async updateZabbixEventComment() {
      this.blockButton = true;
      let postObject = {
        comment: this.description,
        id: this.id
      }

      await this.axios
        .put("/SlaCalculation/AddOrUpdateZabbixSlaEventComment", postObject)
        .then((res) => {
          if (res) {
            this.blockButton = false;
            this.$emit("recalculate");
          }
        })
        .catch((error) => {
          this.$snotify.error(error.message);
        });
      this.blockButton = false;
    },
  },
};
</script>