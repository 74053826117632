<template>
  <div class="zabbixEventList">
    <template>
      <div class="d-flex justify-content-between mb-2">
        <h3>Zabbix SLA Events</h3>
      </div>
      <Grid
        :ref="kgm_ref"
        :style="{ height: 'auto' }"
        :data-items="kgm_computedGridItems(allZabbixEvents)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(allZabbixEvents)"
        :sortable="{
          allowUnsort: true,
          mode: kgm_sortMode,
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="booleanTemplate"
          slot-scope="{ props }"
        >
          <td :class="props.className">
            <input
              :checked="kgm_getNestedValue(props.field, props.dataItem)"
              type="checkbox"
              disabled
              readonly
            />
          </td>
        </template>
        <template
          slot="dateTemplate"
          slot-scope="{ props }"
        >
          <td>
            {{
              dateTime_dateTime(kgm_getNestedValue(props.field, props.dataItem))
            }}
          </td>
        </template>
        <template
          slot="zabbixLink"
          slot-scope="{ props }"
        >
          <td class="alt-primary-color no-wrap-cell">
            <a
              v-tooltip="props.dataItem.triggerName"
              :href="`https://admin.skiline.cc/zabbix/triggers.php?form=update&triggerid=${props.dataItem.triggerId}`"
              target="_blank"
            >{{ props.dataItem.triggerName }}</a>
          </td>
        </template>
        <template
          slot="problemLink"
          slot-scope="{ props }"
        >
          <td class="alt-primary-color no-wrap-cell">
            <a
              :href="`https://admin.skiline.cc/zabbix/tr_events.php?triggerid=${props.dataItem.triggerId}&eventid=${props.dataItem.problemEventId}`"
              target="_blank"
            >{{ props.dataItem.problemEventId }}</a>
          </td>
        </template>
        <template
          slot="chLink"
          slot-scope="{ props }"
        >
          <td
            v-if="props.dataItem.testcaseId"
            class="alt-primary-color"
          >
            <a
              :href="`https://editor.contenthub.dev/spaces/${contentfulSpace}/environments/autotest/entries/${props.dataItem.testcaseId}`"
              target="_blank"
            >{{ props.dataItem.testcaseId }}</a>
          </td>
          <td v-else></td>
        </template>
        <div
          slot="textFilter"
          slot-scope="{ props, methods }"
          class="input-group"
        >
          <input
            ref="textFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a Value"
            @input="
              (event) => {
                methods.change({
                  operator: 'contains',
                  field: props.field,
                  value: event.target.value,
                  syntheticEvent: event,
                });
              }
            "
          />
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="
                kgm_resetInput('textFilterInput', (event) => {
                  methods.change({
                    operator: '',
                    field: '',
                    value: '',
                    syntheticEvent: event,
                  });
                })
              "
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="booleanFilter"
          slot-scope="{ props, methods }"
        >
          <BooleanFilter
            label-true="true"
            label-false="false"
            :value="props.value"
            @changeFilter="
              (ev) => {
                methods.change({
                  operator: ev.operator,
                  field: ev.field,
                  value: ev.value,
                  syntheticEvent: ev.syntheticEvent,
                });
              }
            "
          />
        </div>
        <template
          slot="optionsTemplate"
          slot-scope="{ props }"
        >
          <td :class="props.className">
            <div class="btn-group">
              <template v-if="props.dataItem.isFalsePositive">
                <button
                  class="btn btn-primary btn-sm"
                  @click="openEditSidebar(props.dataItem)"
                >
                  <font-awesome-icon icon="edit" />
                </button>
                <button
                  class="btn btn-secondary btn-sm"
                  @click="openConfirmModal(props.dataItem)"
                >
                  <font-awesome-icon icon="trash" />
                </button>
              </template>
              <template v-else>
                <button
                  class="btn btn-success btn-sm"
                  @click="openAddSidebar(props.dataItem)"
                >
                  <font-awesome-icon icon="plus" />
                </button>
              </template>
              <button
                class="btn btn-secondary btn-sm"
                @click="openCommentSidebar(props.dataItem)"
              >
                <font-awesome-icon icon="comment" />
              </button>
            </div>
          </td>
        </template>
      </Grid>
      <Sidebar
        :show-sidebar="showAddSidebar || showEditSidebar || showCommentSidebar"
        @close="hideSidebars"
      >
        <AddFalsePositiveZabbixEvent
          v-if="showAddSidebar"
          :request-object="requestObject"
          :problem-id="selectedProblemId"
          @recalculate="$emit('recalculate')"
        />
        <EditFalsePositiveZabbixEvent
          v-if="showEditSidebar"
          :request-object="requestObject"
          :problem-id="selectedProblemId"
          @recalculate="$emit('recalculate')"
        />
        <CommentZabbixEvent
          v-if="showCommentSidebar"
          :id="selectedProblem.id"
          :comment="selectedProblem.comment"
          @recalculate="getAllEvents()"
        />
      </Sidebar>
    </template>
    <SweetModal
      ref="markAsNormal"
      title="Remove"
      icon="warning"
      blocking
      class="overflowHidden"
    >
      <p>
        Are you sure you want to remove this Event from the False Positive list?
      </p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.markAsNormal.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t("cancel") }}
      </button>
      <button
        slot="button"
        class="btn btn-danger float-right mb-3"
        @click="removeFalsePositiveEvent()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="trash"
        />{{ $t("delete") }}
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>
import { SweetModal } from "sweet-modal-vue";
import Sidebar from "@/components/Base/Sidebar.vue";
import AddFalsePositiveZabbixEvent from "./AddFalsePositiveZabbixEvent.vue";
import EditFalsePositiveZabbixEvent from "./EditFalsePositiveZabbixEvent.vue";
import CommentZabbixEvent from "./CommentZabbixEvent.vue";

import { kendoGridMixin } from "@/mixins/kendoGridMixin.js";
import { dateTimeMixin } from "@/mixins/dateTimeMixin.js";
import { authenticationMixin } from "@/mixins/authenticationMixin";

export default {
  name: "ZabbixEventList",
  components: {
    Sidebar,
    AddFalsePositiveZabbixEvent,
    EditFalsePositiveZabbixEvent,
    CommentZabbixEvent,
    BooleanFilter: () => import("@/components/CustomFilters/BooleanFilter.vue"),
    SweetModal,
  },
  mixins: [kendoGridMixin, dateTimeMixin, authenticationMixin],
  props: {
    requestObject: {
      type: Object,
      required: true,
    },
    contentfulSpace: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      allZabbixEvents: null,
      showAddSidebar: false,
      showEditSidebar: false,
      selectedProblemId: null,
      selectedProblem: null,
      forDeleteEventId: null,
      showCommentSidebar: false,
      kgm_columns: [
        {
          field: "problemStartTime",
          filterable: true,
          filter: "text",
          title: "Problem Start Time",
          cell: "dateTemplate",
          filterCell: "textFilter",
        },
        {
          field: "problemEndTime",
          filterable: true,
          filter: "text",
          title: "Problem End Time",
          cell: "dateTemplate",
          filterCell: "textFilter",
        },
        {
          field: "problemEventId",
          filterable: true,
          filter: "text",
          title: "Problem Event Id",
          filterCell: "textFilter",
          cell: "problemLink",
        },
        {
          field: "testcaseId",
          filterable: true,
          filter: "text",
          title: "Testcase Id",
          filterCell: "textFilter",
          cell: "chLink",
        },
        {
          field: "triggerId",
          filterable: true,
          filter: "text",
          title: "Trigger",
          cell: "zabbixLink",
          filterCell: "textFilter",
        },
        {
          field: "target",
          filterable: true,
          filter: "text",
          title: "Target",
          filterCell: "textFilter",
        },
        {
          field: "tag",
          filterable: true,
          filter: "text",
          title: "Zabbix Tag",
          filterCell: "textFilter",
        },
        {
          field: "runner",
          filterable: true,
          filter: "text",
          title: "Runner",
          filterCell: "textFilter",
        },
        {
          field: "isFalsePositive",
          filterable: true,
          filter: "boolean",
          title: "False Positive",
          filterCell: "booleanFilter",
          cell: "booleanTemplate",
        },
        {
          filterable: false,
          title: "Options",
          cell: "optionsTemplate",
          hidden: !this.authenticationHasRole("scope_staff"),
        },
      ],
    };
  },
  methods: {
    openConfirmModal(dataItem) {
      this.forDeleteEventId = dataItem.problemEventId;
      this.$refs.markAsNormal.open();
    },
    hideSidebars() {
      this.showAddSidebar = false;
      this.showEditSidebar = false;
      this.showCommentSidebar = false;
      this.selectedProblem = null;
    },
    openEditSidebar(fpEvent) {
      this.selectedProblemId = fpEvent.problemEventId;
      this.showEditSidebar = true;
    },
    openAddSidebar(fpEvent) {
      this.selectedProblemId = fpEvent.problemEventId;
      this.showAddSidebar = true;
    },
    openCommentSidebar(nEvent) {
      this.selectedProblem = nEvent;
      this.selectedProblemId = nEvent.problemEventId;
      this.showCommentSidebar = true;
    },
    async removeFalsePositiveEvent() {
      this.$refs.markAsNormal.close();
      this.blockButton = true;
      await this.axios
        .delete(
          "/SlaCalculation/DeleteFalsePositiveZabbixEvent?problemId=" +
            this.forDeleteEventId
        )
        .then((res) => {
          if (res) {
            this.$emit("recalculate");
          }
        })
        .catch((error) => {
          this.$snotify.error(error.message);
        });
      this.blockButton = false;
    },
    getAllEvents() {
      this.hideSidebars();
      this.loading = true;
      this.axios
        .post(`/SlaCalculation/GetAllZabbixEvents`, this.requestObject)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.allZabbixEvents = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss">
.zabbixEventList .k-grid table tr:hover td {
  background: rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>
